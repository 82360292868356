<template>
	<Modal ref="modal">
		<template #header>
			<h3 class="block-title">Plan Locker Box</h3>
		</template>
		<Alert ref="alert" class="alert-danger"> {{ message }}</Alert>
		<Form ref="form" @submit="onSubmit">
			<div class="col-12">
				<TextareaField name="remark" rules="required" id="remark" v-model="remark" label="Remark" placeholder="Remark" />
			</div>
			<div class="d-grid gap-2 d-md-flex justify-content-md-end">
				<button class="btn btn-alt-secondary me-1 mb-3" @click="onCancel" type="reset">Cancel</button>
				<Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn btn-alt-primary me-1 mb-3" />
			</div>
		</Form>
	</Modal>
</template>

<script lang="ts">
import Easy from '@/models/Easy'
import Vault from '@/models/Vault'
import Alert from '@/vue/components/Alert.vue'
import { ButtonLabel } from '@/vue/components/Button'
import Button from '@/vue/components/Button.vue'
import Form from '@/vue/components/Form.vue'
import Modal from '@/vue/components/Modal.vue'
import TextareaField from '@/vue/components/TextareaField.vue'
import { AxiosError } from 'axios'
import { Component, Prop, Ref, toNative, Vue, Watch } from 'vue-facing-decorator'

@Component({
	components: {
		Form,
		Modal,
		TextareaField,
		Alert,
		Button,
	},
})
class LockerBox extends Vue {
	message = ''

	loading = false
	done = false

	remark = ''

	@Ref('modal')
	readonly modal!: InstanceType<typeof Modal>

	@Ref('form')
	readonly form!: InstanceType<typeof Form>

	@Ref('alert')
	readonly alert!: InstanceType<typeof Alert>

	@Prop({ type: Object })
	readonly plan!: Easy | Vault

	@Watch('message')
	onMessageChanged(value: string) {
		if (value.length > 0) {
			this.alert.show()
		} else {
			this.alert.hide()
		}
	}

	onCancel() {
		this.form.vform.resetForm()
		this.modal.onHideModal()
	}

	get labels(): ButtonLabel {
		return {
			normal: 'Submit',
			loading: 'Submitting',
			done: 'Submitted',
		}
	}

	onSubmit(payload) {
		this.loading = true

		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route(`${this.plan.model}.ajax.lock`, { vault: this.plan.slug }), { type: 'lock', ...payload })
						.then(() => this.$router.reload({ only: [this.plan.model] }))
						.catch((e: AxiosError) => this.$error(e))
						.finally(() => ((this.loading = false), this.modal.onHideModal()))
				}
			})
	}
}

export default toNative(LockerBox)
</script>
